/* General container styling */
.HomeGreyContainer-container {
    position: relative;
    background-color: #B1B6AC;
    padding: 20px;
}

.HomeGreyContainer-container .card-icons {
    padding: 0;
    margin-right: 20px;
    width: 40px;
    height: 40px;
}
.HomeGreyContainer-container .eye-icon {
    width: 30px;
    height: 30px;
}



/* Swiper Navigation Buttons */
.HomeGreyContainer-container .swiper-button-next,
.HomeGreyContainer-container .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(120%);
    width: 50px;
    /* Button size for desktop */
    height: 50px;
    /* Square button */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    background: transparent;
    color: white;
    font-size: 1.5rem;
    /* Arrow size for desktop */
    z-index: 10;
    border-radius: 5px;
    /* Slightly rounded corners */
}

/* Positioning: Move the buttons closer to the content */
.HomeGreyContainer-container .swiper-button-next {
    right: 75px;
    /* Adjust based on larger screen */
}

.HomeGreyContainer-container .swiper-button-prev {
    left: 75px;
    /* Adjust based on larger screen */
}

/* Center arrow inside the button */
.HomeGreyContainer-container .swiper-button-next::after,
.HomeGreyContainer-container .swiper-button-prev::after {
    font-size: 1.2rem;
    /* Arrow size */
}

/* Hover effect */
.HomeGreyContainer-container .swiper-button-next:hover,
.HomeGreyContainer-container .swiper-button-prev:hover {
    background-color: rgba(255, 255, 255, 0.2);
    /* Slight white tint on hover */
    cursor: pointer;
}

.HomeGreyContainer-container .hiden-content
{
    position: relative;
    width: 100%;
    overflow-y: hidden
}

.HomeGreyContainer-container .hiden-content-hide
{
    animation: tab_slide-out .5s forwards;
    -webkit-animation: tab_slide-out .5s forwards
}
.HomeGreyContainer-container .hiden-content-show
{
    animation: tab_slide-in .5s forwards;
    -webkit-animation: tab_slide-in .5s forwards
}

.HomeGreyContainer-container .line-seprator
{
    width: 98%;
    height: 0;
    border: 1.5pt solid #4c595752;
    margin-top: 10px;
    margin-bottom: 10px;
}

.HomeGreyContainer-container .hero-genral-btn
{
    border: 1px solid #4C5957;
    color: #4C5957;
}
.HomeGreyContainer-container .hero-genral-btn:hover
{
    background-color: rgba(255, 255, 255, 0.142);
}

.HomeGreyContainer-container .card-box .body-heading
{
    font-size: 25px!important;
}


@keyframes tab_slide-in {
    0% {
        max-height: 0;
        padding-bottom: 0
    }

    to {
        max-height: 600px;
        padding-bottom: 15px
    }
}

@keyframes tab_slide-out {
    0% {
        max-height: 600px;
        padding-bottom: 15px
    }

    to {
        max-height: 0;
        padding-bottom: 0
    }
}

/* Media query for tablets (up to 993px) */
@media (max-width: 993px) {
    .HomeGreyContainer-container .swiper-button-next {
        right: 25px;
        /* Reduce right margin for smaller screens */
    }
    .HomeGreyContainer-container .swiper-button-prev {
        left: 25px;
        /* Reduce left margin for smaller screens */
    }

    .HomeGreyContainer-container .swiper-button-next,
    .HomeGreyContainer-container .swiper-button-prev {
        width: 40px;
        /* Reduce button size */
        height: 40px;
        font-size: 1.2rem;
        /* Reduce arrow size */
    }
    
    .HomeGreyContainer-container .card-box .body-heading
    {
        font-size: 20px!important;
    }
}

/* Media query for larger mobile devices (up to 768px) */
@media (max-width: 768px) {
    .HomeGreyContainer-container .swiper-button-next {
        right: 15px;
        /* Adjust button closer to content */
    }

    .HomeGreyContainer-container .swiper-button-prev {
        left: 15px;
        /* Adjust button closer to content */
    }

    .HomeGreyContainer-container .swiper-button-next,
    .HomeGreyContainer-container .swiper-button-prev {
        width: 35px;
        /* Reduce button size */
        height: 35px;
        font-size: 1rem;
        /* Reduce arrow size */
    }
}

/* Media query for small mobile devices (up to 576px) */
@media (max-width: 576px) {
    .HomeGreyContainer-container .swiper-button-next {
        right: 5px;
        /* Closer to content */
    }

    .HomeGreyContainer-container .swiper-button-prev {
        left: 5px;
        /* Closer to content */
    }

    .HomeGreyContainer-container .swiper-button-next,
    .HomeGreyContainer-container .swiper-button-prev {
        width: 30px;
        /* Further reduce button size */
        height: 30px;
        font-size: 0.9rem;
        /* Smaller arrow size */
    }
}

/* Media query for extra small devices (up to 360px) */
@media (max-width: 360px) {

    .HomeGreyContainer-container .swiper-button-next,
    .HomeGreyContainer-container .swiper-button-prev {
        width: 25px;
        /* Minimum button size for very small screens */
        height: 25px;
        font-size: 0.8rem;
        /* Minimum arrow size */
    }
}