.PlainText-container {
    background-repeat: no-repeat;
    background-size: cover;

}

.PlainText-container .main-para {
    width: 85%;
}

.PlainText-container .main-para-full {
    width: 100%;
    padding: 0vh 10%;
}

.PlainText-container .stylish-font-text {
    font-size: 5rem;
}

.PlainText-container .card-box {
    text-align: center;
    height: 100%;
}
.PlainText-container .card-box-bg {
    text-align: center;
    padding: 1.5rem ;
    background-color: #F5F3EF;
    height: 100%;
}

.PlainText-container .swiper-slide {
    height: auto;
}

.PlainText-container .swiper-pagination .swiper-pagination-bullet {
    background-color: #FFFFFF;
    opacity: 1;
    width: 13px;
    height: 13px;

}

.PlainText-container .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #4C5957;
    opacity: 1;
    width: 13px;
    height: 13px;
}

.PlainText-container .GreyPricingCardBox .heading-tab {
    background-color: #B1B6AC;
}

.PlainText-container .GreyPricingCardBox .content-tab {
    background-color: #F3F3F3;
}

.PlainText-container .pageChangecard-img
{
  max-width: 100%;
}

.PlainText-container .blog-list
{
    padding-bottom: 0;
}

.PlainText-container .bulletList-container 
{
    width: 85%;
}

@media(max-width:1200px)
{
    .PlainText-container .bulletList-container 
    {
        width: 90%;
    }
}

@media(max-width:993px) {
    .PlainText-container .main-para {
        width: 100%;
    }
    .PlainText-container .main-para-full {
        width: 100%;
        padding: 0vh 0vh;
    }
    .PlainText-container .card-box {
        margin-bottom: 80px;
    }
    .PlainText-container .blog-list
    {
        /* padding-bottom: 100px; */
    }

    .PlainText-container .bulletList-container 
    {
        width: 100%;
    }
    .PlainText-container .pageChangecard-img
    {
        max-width: 80%;
        margin: auto;
    }
}

@media(max-width:500px)
{
    .PlainText-container .pageChangecard-img
    {
        max-width: 90%;
        margin: auto;
    }
}
