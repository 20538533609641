.BlogCard-container
{
    background-color: #F5F3EF;
}

.BlogCard-container .anchor
{
    text-decoration: underline;
    cursor: pointer;
    transition: .3s;
    width: fit-content;
}


.BlogCard-container .title
{
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;

}

.BlogCard-container .date
{
    font-size: 16px;
}
.BlogCard-container .secondary-img-container
{
    height: 370px;
}


.BlogCard-container  .swiper-button-prev-blog
{
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 100;
    cursor: pointer;
    padding-right: 10px;
    padding-top: 30px;
}
.BlogCard-container .swiper-button-next-blog
{
    position: absolute;
    bottom: 0%;
    z-index: 100;
    right: 0%;
    cursor: pointer;
    padding-left: 10px;
    padding-top: 30px;

}
.BlogCard-container .arrow-img
{
    width: 50px;
}

.BlogCard-container .main-para
{
    width: 50%;
}
@media(max-width:993px)
{
    .BlogCard-container .secondary-img-container
    {
        height: 200px;
    }
    .BlogCard-container .swiper-wrapper
    {
        transform: translateX(0px);
    }
    .BlogCard-container .arrow-img
    {
        width: 30px;
    }
    .BlogCard-container  .swiper-button-prev-blog
{
    left: 20%;
}
.BlogCard-container .swiper-button-next-blog
{
    right: 20%;

}
.BlogCard-container .main-para
{
    width: 100%;
}
}