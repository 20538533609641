.news-container
{
    transform: translateY(0px);
}


.news-container .box-1
{
    transform: translateY(0px);
}

.news-container .page-btn-container button
{
    width: 55px;
    height: 55px;
    font-size: 20px;
    background-color: white;
    color: black;
    border: 1px solid black;
}
.news-container .page-btn-container button.active
{
    width: 55px;
    height: 55px;
    font-size: 20px;
    background-color: #E0C7C0;
    color: white;
    border: 1px solid #E0C7C0;
}

@media(max-width:992px)
{
    .news-container .box-1
{
    transform: translateY(0px);
}
}