.blog-details-container
{

}

.blog-details-container .container
{
}

.blog-details-container .blog-thumbnail
{
    width: 80%;
}

.blog-details-container .text-content .content-title
{
    color: #203354;
    font-weight: 600;
    font-size: 1.8rem;
}
.blog-details-container .text-content p
{
    line-height: 2rem;
}

.blog-details-container .social-icon-box{
    gap: 5px;
}

.blog-details-container .social-icon-box .social-icon{
    width: 40px;
    height: 40px;
}
.blog-details-container .line-seprator
{
    width: 98%;
    border: 1px solid #CCCCCC;
    margin: auto;
}