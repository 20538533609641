.slider-container {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  
.slider-container  .slider-wrapper {
    display: flex;
    align-items: start;
    justify-content: start;
    overflow-x: hidden; /* Prevent showing scrollbars */
    scroll-behavior: smooth;
    width: 100vw;
    transition: transform 0.3s ease; /* Smooth transition */
  }
  
.slider-container  .slider-item {
    min-width: 200vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    font-size: 2rem;
    text-align: center;
  }
  

 
  

  .slider-container .first-cont
  {
    background-image: url("../../../public/images/creatives/service-caurosel-1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 50%;
  }
  .slider-container .last-cont
  {
    background-image: url("../../../public/images/creatives/service-caurosel-1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 20%;
  }
  .slider-container .text-cont
  {
   background-color: white;
    width: 30%;
  }
  .slider-container .sec-cont
  {
    background-image: url("../../../public/images/creatives/service-caurosel-1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 50%;
  }


  @media(max-width:993px)
  {
    
  .slider-container .first-cont
  {
    background-image: url("../../../public/images/creatives/service-caurosel-1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 20%;
  }
  .slider-container .last-cont
  {
    background-image: url("../../../public/images/creatives/service-caurosel-1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 20%;
  }
  .slider-container .text-cont
  {
   background-color: white;
    width: 80%;
  }
  .slider-container .sec-cont
  {
    background-image: url("../../../public/images/creatives/service-caurosel-1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 40%;
  }

  }