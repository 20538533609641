.ServiceSplitContentSecond-container {
    background-repeat: no-repeat;
    background-size: cover;
}

.ServiceSplitContentSecond-container .stylish-font-text {
    font-size: 4.5rem;
}

.ServiceSplitContentSecond-container .main-para {
    padding: 0vh 0vh;
}

.ServiceSplitContentSecond-container .main-card
{
    background-color: white;
}

.ServiceSplitContentSecond-container .side-img
{
    display: block;
}

@media(max-width:993px)
{
    .ServiceSplitContentSecond-container .side-img
    {
        /* display: none; */
    }
}

@media(max-width: 768px) {
    .ServiceSplitContentSecond-container .main-para {
        padding: 0vh 0vh;
    }
}

@media(max-width: 1024px) {
    .ServiceSplitContentSecond-container .main-para {
        padding: 0vh 0vh;
    }
}