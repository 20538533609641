.faq-container {}

.faq-container .icon-image {
    width: 100px;
}


.faq-container .text-content .user-img {
    width: 150px;

}

.faq-container .text-content {

    font-size: 16px;
    font-weight: normal;
    /* color: #373737; */
}

.faq-container .dropdown-box {
    cursor: pointer;
    width: 100%;
    background-color: transparent;
    padding: 20px 1px;
}

.faq-container .line-seprator {
    width: 99%;
    border: 1px solid rgba(128, 128, 128, 0.199);
    margin: auto;

}

.faq-container .dropdown-box .box-head .title {
    font-weight: bold;

    color: #515151;
    font-size: 19px;
}

.faq-container .eye-icon {
    width: 40px;
    height: 35px;
    padding: 10px;
}

.faq-container .faq-main-content {
    max-width: 80%;

}

.faq-container .dropdown-box a {
    color: #BAA29C !important;
}

.faq-container .dropdown-container
{
    width: 50%;
}
/* 
@media(max-width:1200px) {
    .faq-container .faq-main-content {
        max-width: 100%;
    }

    .faq-container .dropdown-box {
        cursor: pointer;
        width: 100%;
        background-color: transparent;
        padding: 30px 20px;
    }

    .faq-container .icon-image {
        width: 80px;
    }

    .faq-container .dropdown-container
{
    width: 100%;
}
} */
@media(max-width:993px) {
    .faq-container .faq-main-content {
        max-width: 100%;
    }

    .faq-container .dropdown-box {
        cursor: pointer;
        width: 100%;
        background-color: transparent;
        padding: 30px 20px;
    }

    .faq-container .icon-image {
        width: 80px;
    }

    .faq-container .dropdown-container
{
    width: 100%;
}
}


@keyframes bottomBoxOpen {
    from {
        display: none;
        transform: translateY(-3px);
    }

    to {
        display: block;
        transform: translateY(0px);
    }
}

@keyframes bottomBoxClose {
    from {
        display: block;
        transform: translateY(0px);
    }

    to {
        transform: translateY(-3px);
        display: none;
    }
}

@media(max-width:768px) {
    .faq-container .icon-image {
        width: 78px;
    }
}