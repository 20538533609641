@font-face {
  font-family: BrownFont;
  src: url(../public/heading-text.ttf);
}
@font-face {
  font-family: Aervish;
  src: url(../public/Aervish.ttf);
}
@font-face {
  font-family: 'parafont';
  src: url('../public/para-text.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
* {
  margin: 0; 
  padding: 0;
  scroll-behavior: smooth; 
  font-family: "parafont", sans-serif;
}


.font-Aervish
{
  font-family: "Aervish", sans-serif!important;
 
}

.font-lato
{
  font-family: "parafont", sans-serif!important;
 
}
.font-mons
{
  font-family: "BrownFont", sans-serif!important;
 
}

.color-dark
{
  color: #555555!important;
}
.color-light
{
  color: white!important;
}

.color-blue
{
  color: #8EA9A9!important;
}

.standard-margin-space {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.standard-padding-space {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.hero-section-heading { 
  /* font-weight: 700; */
  text-transform: capitalize;
  /* font-family: 'Noto Sans', sans-serif; */
}
.hero-section-heading span{ 
  /* font-weight: 700; */
  text-transform: capitalize;
  /* font-family: 'Noto Sans', sans-serif; */
}

.body-heading {
  font-size: 2.5rem;
  color: #4C5957;
  letter-spacing: 1px;
  font-weight: bold;
  font-family: "BrownFont", sans-serif!important;
text-transform: capitalize;
line-height: 1.4;
  }

.hero-section-heading  
{ 
  font-size:4.6rem;
  color: #ffffff;
  font-weight:300;
  font-family: "BrownFont", sans-serif!important;
}
.hero-section-heading-small
{
  font-size: 3.1rem!important;
  color: #ffffff;
  font-weight: 400;
}
.hero-section-heading span {font-family: "BrownFont", sans-serif!important;}
  
.body-paragraph { 
  font-size: 1.1rem;
  color: #000000;
  font-weight:normal;
  line-height: 2;
  font-family: "parafont", sans-serif!important;
}

.hero-paragraph
{
  font-size: 1.25rem;
  color: #ffffff;
  font-weight: 300;
  font-family: "parafont", sans-serif!important;
}

.hero-genral-btn
{
  padding: .8rem 2rem;
  border: 1px solid white;
  font-size: 0.9rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: normal;
  font-family: "parafont", sans-serif!important;
  background: transparent;

  transition:.3s;
}
.hero-genral-btn:hover
{
  
  background: white;
  color: black;

}
  

.genral-btn{ 
  padding: .8rem 2rem;
  border: 1px solid #4C5957;
  font-size: 0.9rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: white;
  font-weight: normal;
  font-family: "parafont", sans-serif!important;
  background: #E0C7C0;

  transition:.3s;
}
.genral-btn:hover{ 
  background: #c1a9a2;
  border: 1px solid #E0C7C0;;
  color: white;

}
.genral-btn-light{ 
  
  padding: 1rem 2rem;
  border: 1px solid #E0C7C0;
  font-size: 0.9rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: white;
  font-weight: normal;
  font-family: "parafont", sans-serif!important;
  background: #E0C7C0;
  
  transition:.3s;
}
.genral-btn-light:hover{ 
  background: #c1a9a2;
  border: 1px solid #E0C7C0;;
  color: white;

}

.hero-sub-heading
{
  font-size: 1.4rem;
  text-transform: capitalize;
  color: #ffffff;
  font-weight: 600;
  font-family: "BrownFont", sans-serif!important;
}
.sub-heading
{
  font-size: 1.87rem;
  text-transform: capitalize;
  font-weight: 300;
  color: #60645C;
  font-family: "BrownFont", sans-serif!important;

}

.genral-btn span {
  margin-right: .4rem;
}

.elementor-widget-container .body-heading{
  font-size: 1.62rem;
}

.less-spaced-heading{
    word-spacing:normal;
}

@media (min-width: 993px) and (max-width: 1500px){
  .body-heading  {
    font-size: 28px!important;
  letter-spacing: 1px;
  line-height: 1.4;
  }
 
  .hero-section-heading  {
    font-size: 50px;
  }

  .body-paragraph {
    
  /* line-height: 1.2; */
    font-size: 15px;
  }

  .genral-btn-light{ 
  
    padding: .6rem 1.7rem;
    font-size: 0.7rem;
   
  }
  .genral-btn{ 
    padding: .6rem 1.7rem;
    font-size: 0.7rem;
  }
  .hero-genral-btn  
  {
  padding: .6rem 1.7rem;
  font-size: 0.7rem;
  }
  .nav-links
  {
    font-size: 0.9rem!important;
  }
  .sub-heading
  { 
  font-size: 1.2rem!important;
  }
  .review-text
  {
    
    font-size: 15px!important;
  }
  .anchor
  {
    font-size: 13px!important;
  }
  .lower-container p{
    font-size: 15px!important;
  }
  .less-spaced-heading{
    word-spacing:normal;
}
}

@media (max-width: 1250px) {
  .body-heading  {
    font-size: 37px;
    letter-spacing: 0px;
    line-height: 36px;
  }

  .hero-section-heading {
    font-size: 45px;
  }
  .less-spaced-heading{
    word-spacing:normal;
}
}

/* @media (max-width: 460px) {
  .body-heading  {
    font-size: 25px;
  }
 
  .hero-section-heading  {
    font-size: 30px;
  }

  .body-paragraph {
    
  line-height: 1.5;
    font-size: 13px;
  }
  .container{
    padding-left: 7%!important;
    padding-right: 7%!important;
  }
  .anchor
  {
    font-size: 13px!important;
  }
  .sub-heading
  { 
  font-size: 1.3rem!important;
  }
  .hero-section-heading-small
  {
    font-size: 28px!important;
  }
}

@media (max-width: 380px) {
  .body-heading  {
    font-size: 27px;
  }

  .hero-section-heading  {
    font-size: 32px;
  }

  .body-paragraph {
    font-size: 14px;
  }

  .sub-heading {  
  } 
} */



@media (max-width: 460px) {
  .body-heading  {
    font-size: 30px;
  }
 
  .hero-section-heading  {
    font-size: 40px;
  }

  .body-paragraph {
    
  line-height: 1.2;
    font-size: 15px;
  }
  .hero-section-heading-small
  {
    font-size: 38px!important;
  }
  .less-spaced-heading{
    word-spacing:-5px;
}
}

@media (max-width: 380px) {
  .body-heading  {
    font-size: 27px;
  }

  .hero-section-heading  {
    font-size: 32px;
  }

  .body-paragraph {
    font-size: 14px;
  }

  .sub-heading {  
    /* font-size: 12px;  */
  } 
}


/* Width, color, and radius for the track */
::-webkit-scrollbar {
  width: 12px;
}

::-moz-scrollbar {
  width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  transition: .3s;
  border-radius: 3px;
}

::-moz-scrollbar-thumb {
  background: #2a2a2a;
  transition: .3s;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2a2a2a;
}

::-moz-scrollbar-thumb:hover {
  background: #2a2a2a;
}

/* Track */
::-webkit-scrollbar-track {
  transition: .3s;
  background: #e6e6e6;
}
 
::-moz-scrollbar-track {
  transition: .3s;
  background: #e6e6e6;
}
/* Track on hover */
::-webkit-scrollbar-track:hover {
  transition: .3s;
  background: #e6e6e6;
}

::-moz-scrollbar-track:hover {
  transition: .3s;
  background: #e6e6e6;
}

/* Handle when dragging */
::-webkit-scrollbar-thumb:active {
  background: #2a2a2a;
}

::-moz-scrollbar-thumb:active {
  background: #2a2a2a;
}

/* Track when dragging */
::-webkit-scrollbar-track:active {
  transition: .3s;
  background: #e6e6e6;
}

::-moz-scrollbar-track:active {
  transition: .3s;
  background: #e6e6e6;
}
