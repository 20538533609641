.ServiceSplitContentFirst-container {
    background-repeat: no-repeat;
    background-size: cover;
}

.ServiceSplitContentFirst-container .stylish-font-text {
    font-size: 4.5rem;
}

.ServiceSplitContentFirst-container .main-para {
    padding: 0vh 0vh;
}

.ServiceSplitContentFirst-container .split-img
{
    /* width:150%;
    position:relative;
    left:-50%;
    z-index: 1; */
    width: 100%;
    transform:translateY(17%);
}

.ServiceSplitContentFirst-container .main-card
{
    position: relative;
    z-index: 5;
    background-color: white;
}

@media(max-width:993px)
{
    .ServiceSplitContentFirst-container .split-img
    {
        /* width:100%;
        position:relative;
        left:0;
        z-index: 1; */
        transform:translateY(0%);
        /* display: none; */
    }

    .ServiceSplitContentFirst-container .main-card
    {
        position: relative;
        z-index: 5;
        background-color: white;
    }
}

@media(max-width: 768px) {
    .ServiceSplitContentFirst-container .main-para {
        padding: 0vh 0vh;
    }
}

@media(max-width: 1024px) {
    .ServiceSplitContentFirst-container .main-para {
        padding: 0vh 0vh;
    }
}