body{
    background-color: #ffffff;
}
p,h1,h2,h3,h4,h5,h6,a,button,img,small
{
    user-select: none;
}

.home-page
{
    /* background-image: url("../public/images/creatives/home-page-bg.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
}

.left-gradient
{
    background-repeat: no-repeat!important;
    background: radial-gradient(circle at 0% 50%, rgba(255, 255, 255, 0.157) 0%, #ffffff 25%);
    background-size:100% 100%;
}

@media(min-width:1200px)
{
    .left-gradient
    {
        background-repeat: no-repeat!important;
        background: radial-gradient(ellipse at 0% 50%, rgba(250, 250, 250, 0.109) 0%, #ffffff 60%);

        background-size:30% 100%;
    }

}

@media(max-width:992px)
{
    .left-gradient
    {
        background: radial-gradient(ellipse at 0% 60%, rgba(255, 255, 255, 0.157) 0%, #ffffff 60%);
        background-size:40% 100%;
    }
}
.left-gradient-upper
{
    background-repeat: no-repeat!important;
    background: radial-gradient(circle at 5% 50%, rgba(255, 255, 255, 0.057) 0%, #00000000 25%);
    background-size:100% 100%;
}

@media(min-width:1200px)
{
    .left-gradient-upper
    {
        background-repeat: no-repeat!important;
        background: radial-gradient(ellipse at 0% 0%, rgba(255, 255, 255, 0.234) 0%, #00000008 60%);

        background-size:40% 100%;
    }

}

@media(max-width:992px)
{
    .left-gradient-upper
    {
        background: radial-gradient(ellipse at 0% 60%, rgba(113, 155, 199, 0.055) 0%, #ffffff00 60%);
        background-size:40% 100%;
    }
}

.right-gradient
{
    background-repeat: no-repeat!important;
    background: radial-gradient(circle at 100% 50%, rgba(255, 255, 255, 0.157) 0%, #ffffff00 30%);

    background-size:100% 100%;
    background-position:right center;
}

@media(max-width:992px)
{
    .right-gradient
    {
        
        background-size:100%;
    }
}
.right-gradient-bottom
{
    background-repeat: no-repeat!important;
    background: radial-gradient(circle at 100% 90%, rgba(255, 255, 255, 0.157) 0%, #ffffff00 30%);

    background-size:100% 100%;
    background-position:right center;
}

@media(max-width:992px)
{
    .right-gradient-bottom
    {
        
        background-size:100%;
    }
}
.bottom-gradient
{
    background-repeat: no-repeat!important;
    background: radial-gradient(circle at left bottom, rgba(253, 254, 255, 0.077) 0%, rgba(255, 255, 255, 0.062) 56%, rgba(255, 255, 255, 0) 93%, #ffffff00 100%);

    background-size:100% 100%;
    background-position:left bottom;
}

@media(max-width:992px)
{
    .bottom-gradient
    {
        
        background-size:100%;
    }
}


/* blog  */

.blog-Catagory .main-catagory-container
{
    background:#ffffff;
    filter: drop-shadow(0px 3px 15px rgba(0,0,0,0.16));
}
.blog-Catagory .catagory-container
{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    margin: 0!important;
}
.blog-Catagory .active
{
    background-color: #729bc7;
}
.blog-Catagory .active p
{
    color: white!important;
}
.blog-Catagory .main-catagory-container .body-heading
{
    font-size: 15px!important;
    font-weight: normal;
    height: 100%;
}

.bg-grey
{
    background-color: #F3F3EF;
}
   

@media(min-width:1300px)
{
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
        max-width: 85%!important;
    }
}


.term-of-service-container p,
.term-of-service-container li,
.term-of-service-container p>span,
.cookie-policy-container p,
.cookie-policy-container li,
.cookie-policy-container p>span,
.privacy-policy-container p{
    /* text-transform: capitalize; */
    line-height: 1.7;
}

.term-of-service-container h2>span,
.cookie-policy-container h2>span,
.privacy-policy-container h2>span,
.term-of-service-container strong>span,
.cookie-policy-container strong>span,
.privacy-policy-container strong>span,
.term-of-service-container h2,
.cookie-policy-container h2,
.privacy-policy-container h2
{
   
    font-size: 25px;
    color: #4C5957;
    letter-spacing: 1px;
    font-weight: bold;
    font-family: "BrownFont", sans-serif!important;
    text-transform: capitalize;
    line-height: 1.4;
}