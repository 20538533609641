.SocialMediaFeed-container
{
    transform: translateY(-55px);
    background-color: #F5F3EF;
}

.SocialMediaFeed-container .stylish-font-text
{
    font-size: 76px;
}

.SocialMediaFeed-container .feedCard-box
{
    /* height: 340px; */
    width: 100%;
    border: 8px solid white;
    background-repeat: no-repeat;
    background-size: cover;

}

