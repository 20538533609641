.CenteredText-container
{
   
}

.CenteredText-container .card-text-left{
    transform:translateX(-115px);
}
.CenteredText-container .card-text-right{
    transform:translateX(115px);
}
.card-text
{
    max-width: auto;
}
.CenteredText-container .stylish-font-text
{
    font-size:4rem;
    color: white;
}
@media(max-width:1300px)
{
    .CenteredText-container .checkBox-list
    {
        padding-left: 100px;
        padding-right: 100px;
    }
    .card-text
    {
        max-width: 200px;
    } 
}
@media(max-width:1200px)
{
    .CenteredText-container .checkBox-list
    {
        padding-left: 50px;
        padding-right: 50px;
    }
    .card-text
    {
        max-width: 200px;
    } 
}

@media(min-width:1500px)
{
    .CenteredText-container .card-text-left{
        transform:translateX(-145px);
    }
    .CenteredText-container .card-text-right{
        transform:translateX(145px);
    }
    .card-text
    {
        max-width: 320px;
    }

}

@media(min-width:1800px)
{
    .CenteredText-container .card-text-left{
        transform:translateX(-170px);
    }
    .CenteredText-container .card-text-right{
        transform:translateX(170px);
    }
    .card-text
    {
        max-width: 320px;
    }

}