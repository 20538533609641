.work-industry-container {
    background-color: #ffffff;
    padding: 0vh 15%;
}

.work-industry-container .industry-list .industry-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.work-industry-container .industry-list .industry-card .card-icon {
    width: 80px;
    height: 80px;
}

.work-industry-container .industry-list .industry-card .card-icon-container {
    border: none;
    padding-bottom: 0px;
    width: fit-content;
}

.work-industry-container .swiper-button-prev-ind {
    position: absolute;
    top: 35%;
    left: 0;
    z-index: 10;
    cursor: pointer;
    padding-right: 10px;

}

.work-industry-container .swiper-button-next-ind {
    position: absolute;
    top: 35%;
    z-index: 10;
    right: 0;
    cursor: pointer;
    padding-left: 10px;

}

@media (max-width: 993px) {
    .work-industry-container {
        padding: 0vh 0vh;
        /* Reduce padding for smaller screens */
    }
}