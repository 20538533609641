.ServiceSplitContent-container {
    background-repeat: no-repeat;
    background-size: cover;
}

.ServiceSplitContent-container .stylish-font-text {
    font-size: 4.5rem;
}

.ServiceSplitContent-container .main-para {
    padding: 0vh 14vh;
}

@media(max-width: 768px) {
    .ServiceSplitContent-container .main-para {
        padding: 0vh 0vh;
    }
}

@media(max-width: 1024px) {
    .ServiceSplitContent-container .main-para {
        padding: 0vh 0vh;
    }
}