.ContactPageContactDetails-container
{

}

.ContactPageContactDetails-container .details-anchor:hover
{
    color:rgb(224, 199, 192);
}

.ContactPageContactDetails-container .container{
    
    /* transform: translateY(-100px); */
    background-color: white;
}