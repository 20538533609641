.header {
  /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0.759), rgba(255, 255, 255, 0.69),rgba(255, 255, 255, 0.576), rgba(255, 255, 255, 0)); */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.912), rgba(255, 255, 255, 0.813),rgba(255, 255, 255, 0.74), rgba(255, 255, 255, 0));
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .header-wrapper {

  width: 100%;
  margin: auto;
}

.header .header-wrapper .navbar .navbar-container {
  width: 100%;
}

.header .header-wrapper .navbar .navbar-container .nav-logo {
  max-width: 400px;
  /* height: 65px; */
  margin-bottom: 0px;
}

.header .main-nav {
  list-style-type: none;
  margin: 0;
}

.header .nav-links,
.logo {
  text-decoration: none;
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}

.header .nav-links:active,
.logo:active {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}

.header .nav-links:focus,
.logo:focus {
  color: #ffffff;
  font-weight: 400;
}

.header .main-nav li {
  text-align: center;
  color: #ffffff;
}

.header .nav-list .nav-links {
  color: #60645C;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  transition: .3s;
  text-transform: capitalize !important;
}

.header .nav-list .nav-links:hover {
  color: #d0d0d0;
}

.header .extras-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.header .responsive-popup-menu {
  z-index: 1;
  position: absolute;
  top: 100px;
  background-color: rgb(255, 255, 255);
  color: black;
}

.header .responsive-menu-btn img {
  width: 25px;
  height: 25px;
}




.header .extras-btns .blue-btn img {
  width: 17px;
  margin-right: 3px;
}

.header .resp-nav-list .nav-links {
  color: black;
}

.header .resp-nav-list li {
  list-style: none;
  margin-bottom: 4%;
}

.header .resp-nav-list {
  width: 100%;
}


.header .nav-buttton button {
  min-width: 220px !important;
  text-transform: capitalize !important;
}

/* Dropdown css */

.navigation-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-trigger {
  cursor: pointer;
  color: white;
}

.dropdown-content {
  position: absolute;
  top: 130%;
  left: -37%;
  background-color: white;
  min-width: max-content;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 0px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.dropdown-content .nav-links {
  color: black !important;
  list-style: none;
  text-decoration: none;
}

.dropdown-content .nav-links:hover {
  color: #5e5e5e !important;
}


/* Custom CSS Animation */
.dropdown-content-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.dropdown-content-enter-active {
  display: block;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.dropdown-content-exit {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-content-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}

.header .arrow-icon {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.header .rotate-icon {
  animation: rotate180 .3s forwards;
}

.header .hero-genral-btn
{
  
  color: #60645c;
  border: 1px solid #60645c;
}


@keyframes rotate180 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

/* Return to original position */
.header .rotate-back {
  animation: rotateBack .1s forwards;
}

@keyframes rotateBack {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}


.header .nav-list .none-delay {
  display: none;
}

.header .nav-list .open {
  display: flex;
}

@media(max-width:1460px) {
  .header .nav-list .nav-links {
    font-size: 16px;
  }

  .header .nav-buttton button {
    min-width: auto !important;
  }

}
@media(max-width:1360px) {
  .header .nav-list .nav-links {
    font-size: 14px;
  }

  .header .nav-buttton button {
    min-width: auto !important;
  }

}


@media(max-width : 1400px) {
  .header .header-wrapper .navbar .navbar-container .nav-logo {
    max-width: 200px;
    height: 57px;
  }

}
@media(max-width : 1200px) {
  .header .header-wrapper .navbar .navbar-container .nav-logo {
    max-width: 200px;
    height: 57px;
    margin-bottom: 0;
  }

  .header .nav-buttton button {
    min-width: max-content !important;
  }

  .header .header-wrapper
  {
    max-width: 100% !important;
    margin-left: 0!important;
    margin-right: 0!important;
  }
}


.nav_slide {
  position: relative;
  width: 100%;
  overflow-y: hidden;
}

.nav_slide-in {
  animation: nav_slide-in 0.5s forwards;
  -webkit-animation: nav_slide-in 0.5s forwards;
}

.nav_slide-out {
  animation: nav_slide-out 0.5s forwards;
  -webkit-animation: nav_slide-out 0.5s forwards;
}

.responsive-popup-menu-mob {
  width: fit-content;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 85%;
}

@keyframes nav_slide-in {
  0% {
    transform: translateX(100%);
    max-height: 0;
    min-height: 0;
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
  }
  to {
    transform: translateX(0);
    max-height: 1000px;
    overflow: hidden;
    min-height: 100vh;
    padding-bottom: 35px;
    padding-top: 35px;
  }
}

@keyframes nav_slide-out {
  0% {
    transform: translateX(0);
    max-height: 1000px;
    padding-top: 35px;
    padding-bottom: 35px;
  }
  to {
    transform: translateX(100%);
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.nav_slide-sub {
  /* position: relative; */
  width: 100%;
  overflow-y: hidden;
}

.nav_slide-in-sub{
  padding-left: 15px!important;
  animation: nav_slide-in-sub .5s forwards;
  -webkit-animation: nav_slide-in-sub .5s forwards
}

.nav_slide-out-sub {
  animation: nav_slide-out-sub 0s forwards;
  -webkit-animation: nav_slide-out-sub 0s forwards
}

@keyframes nav_slide-in-sub {
  0% {
      max-height: 0;
      padding-bottom: 0;
      padding-top: 0;
  }

  to {
      max-height: 1000px;
      padding-bottom: 5px;
      padding-top: 5px;
  }
}

@keyframes nav_slide-out-sub {
  0% {
      max-height: 1000px;
      padding-top: 5px;
      padding-bottom: 5px
  }

  to {
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0
  }
}