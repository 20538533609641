.TestimonialsPageSlider-container
{
    
    /* background: linear-gradient(90deg, rgba(252,243,255,1) 0%, rgba(243,243,243,1) 100%); */
    
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.TestimonialsPageSlider-container .container{
    
    transform: translateY(-120px);
    background-color: white;
}

.TestimonialsPageSlider-container .TestimonialsPageSlider-card
{
    cursor: pointer;
    border-radius: 5px;    
    background-image: url("../../../public/images/icons/testimonial-page-card-bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    /* filter: drop-shadow(0 3px 20.5px rgba(0, 0, 0, 0.063)); */
    
}
.TestimonialsPageSlider-container .TestimonialsPageSlider-card .arrow-img
{
    width:45px;
}


.TestimonialsPageSlider-container .TestimonialsPageSlider-card .testimonial-card-info
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}


.TestimonialsPageSlider-container .profile-info
{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.TestimonialsPageSlider-container .user-img{
    border-radius: 50%;
    width: 125px;
    transform: translateY(-30px);
    filter: drop-shadow(0px 3px 9px rgba(0,0,0,0.16));
}

.TestimonialsPageSlider-container .profile-info .review-text
{
    color: #cecece;
    font-size: 19px;
    font-weight: 300;
    margin-bottom: 30px;
    line-height: 1.8rem;
    width: 65%;
    /* max-height: 150px;
    overflow-y: auto; */
}

.TestimonialsPageSlider-container .review-text-main
{

    max-height: 300px;
    overflow-y: auto;
    width: 100%!important;

}

.TestimonialsPageSlider-container .review-text-main::-webkit-scrollbar {
    width: 4px;
}
.TestimonialsPageSlider-container .testimonial-card-line-seprator
{
    border: 1px solid #939393;
    width: 80%;
    height: 0;
    
}
.TestimonialsPageSlider-container .profile-info .name
{
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 10px;
    text-transform: uppercase;
}

.TestimonialsPageSlider-container .btn-container .btn-arrow-icon
{
    width: 14px;
    height: 14px;
}
.TestimonialsPageSlider-container .btn-container .btn-arrow-icon-left
{
    transform: rotate(130deg);
    transition:transform .3s ;
}
.TestimonialsPageSlider-container .btn-container .btn-arrow-icon-left-rotate
{
    transition:transform .3s ;
    transform: rotate(180deg);
}

.TestimonialsPageSlider-container .btn-container
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
/* 
.TestimonialsPageSlider-container .swiper-slide
{
    transform: translate(50% , 25px);
    overflow-y: visible;
    display: block;
    
}
.TestimonialsPageSlider-container .swiper-slide-active
{
    transform: translate(-50% , 25px);
    display: block;
}
.TestimonialsPageSlider-container .swiper-slide-next
{
    transform: translate(0 , 0)!important;
    display: grid;
    align-items: center;
    justify-content: center;
} */

/* 
.TestimonialsPageSlider-container .card-list::before
{
    content: " ";
    position: absolute;
    height: 100%;
    width: 40%;
    left: 0;
    top: 0;
    z-index: 100;
    background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.406) 24%, rgba(255, 255, 255, 0.631) 46%, rgba(255, 255, 255, 0.863) 74%,rgb(255, 255, 255) 90%, rgb(255, 255, 255) 100%);
}


.TestimonialsPageSlider-container .card-list::after
{
    content: " ";
    position: absolute;
    height: 100%;
    width: 40%;
    right: 0;
    top: 0;
    z-index: 100;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.406) 24%, rgba(255, 255, 255, 0.631) 46%, rgba(255, 255, 255, 0.863) 74%,rgb(255, 255, 255) 90%, rgb(255, 255, 255) 100%);

} */

.TestimonialsPageSlider-container .swiper-pagination
{
    /* width: fit-content!important; */
   bottom: -45px!important;
   position: relative;
}
.TestimonialsPageSlider-container .swiper-pagination .swiper-pagination-bullet
{
    background: #c8c8c8 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 15px;
    height:15px;
    
}
.TestimonialsPageSlider-container .swiper-pagination .swiper-pagination-bullet-active
{
    background: #E0C7C0 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 15px;
    height:15px;
}

.TestimonialsPageSlider-container .main-heading
{
    max-width: 50%;
    /* background-image: url("../../../public/images/icons/scratch-pink.svg"); */
    background-position: center;
    background-repeat: no-repeat;
}

.TestimonialsPageSlider-container .swiper-button-prev-test,
.TestimonialsPageSlider-container .swiper-button-next-test
{
    cursor: pointer;

}


.TestimonialsPageSlider-container .stylish-font-text
{
    font-size: 76px;
}

@media(max-width:992px)
{
    
    .TestimonialsPageSlider-container .main-heading
    {
        max-width: 100%;
    }
    .TestimonialsPageSlider-container .card-list::before
    {
        content: none;
    }
    .TestimonialsPageSlider-container .card-list::after
    {
        content: none;
    }
    .TestimonialsPageSlider-container .profile-info .review-text
    {
       
        width: 100%;
        
    }
}




@media(max-width: 576px)
{
    .TestimonialsPageSlider-container .swiper-slide .TestimonialsPageSlider-card
    {
        width: 100%;
        margin: auto;
    }
}