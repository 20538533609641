.CourseSection-container
{
    /* height: auto;
    background-image: url("../../../public/images/creatives/course-section-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover; */
    position: relative;
}
.CourseSection-container .section-bg
{
    width: 100%;
    display: block;
}

.CourseSection-container .body-heading
{
    width: 50%;
    margin: auto;
}

.CourseSection-container .text-content
{
    
    position: absolute;
    top: 7%;
    width: 100%;
    padding-bottom: 0px;
   padding-top: 0;
}

@media(max-width:1200px)
{
    .CourseSection-container .text-content
    {
        
        position: absolute;
        top: 1%;
        width: 100%;
        padding-bottom: 0px;
    padding-top: 10px;
    }
}

/* @media(max-width:993px)
{
    .CourseSection-container
{
    
    background-image: url("../../../public/images/creatives/course-section-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: static;
    height: auto;
    background-position: center;
}
.CourseSection-container .section-bg
{
    width: 100%;
    display: none;
}

.CourseSection-container .body-heading
{
    width: 80%;
    margin: auto;
}

.CourseSection-container .text-content
{
    padding-bottom: 700px;
    position: static;
    top: 0px;
    padding-top: 60px;
    width: 100%;
}

} */

@media(max-width:993px)
{
    .CourseSection-container
{
    background-image: url("../../../public/images/creatives/course-section-bg-mobile.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: static;
    height: auto;
    background-position: bottom;

}
.CourseSection-container .section-bg
{
    width: 100%;
    display: none;
}

.CourseSection-container .body-heading
{
    width: 80%;
    margin: auto;
}

.CourseSection-container .text-content
{
    padding-bottom: 54%;
    position: static;
    top: 0px;
    padding-top: 60px;
    width: 100%;
}

}