.EGiftCard-container{
    background-color: #F2F2F2;
}

@media(max-width:1300px)
{
    .EGiftCard-container .gift-card-img
    {
        max-height: 300px;
    }   
}


@media(max-width:993px)
{
    .EGiftCard-container .gift-card-img
    {
        max-height: 400px;
    }   
}
