.news_card .blog_list_logo
{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    margin-top: auto;
}
.news_card
{
    padding: 0;
    width: 95%;
    margin: auto;
    color: white;
    
    background-color: white;
    transition: .3s;
}


.news_card .blog_list_logo_img
{
    width: 40px;
}

.news_card .blog_list_logo_title
{
    font-size: .8rem;
    font-weight: 600;
    color: #2D2D2D;
}

.news_card .blog_list_logo_date
{
    font-size: .7rem;
    color: #AFAFAF;
}

.news_card .sub_text
{
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    letter-spacing: 2.80px;
    color: #DB831C;
}
.news_card .card_text
{
    min-height: 180px;
}

.news_card .card_img
{
    width: 100%;
    /* height: 240px; */
}
.news_card .card_title
{
    text-transform: uppercase;
    font-size: 1.5rem; 
    color: #868686!important;
    /* font-weight:600; */
}
.news_card .body-paragraph
{
    color: #868686!important;
}
.news_card .h4_main
{
    
    width: 90%!important;
    color: #868686!important;
}

.news_card .line-seprator
{
    width: 100%;
    height: 0;
    border-bottom: 1px solid rgb(55, 55, 55);
    margin-bottom: 15px;
}
.news_card .anchor
{
    
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: underline;
    color: #868686!important;
    transition: .3s;
}
.news_card .anchor:hover
{
    color: #ffffff!important;
}

.news_card .main-content
{
    width: 100%;
    
}
.news_card .main-content .body-heading
{
    font-size:25px!important;

}

.news_card .first-text-border
{
    border: 3px solid #D5E1DC;
}

.line .line-seprator
{
    border: 2px solid #D5E1DC;
    width: 100%;
    height: 0px;
    margin: auto;
}

@media(max-width:1000px)
{
    .news_card{
        width: 80%;
        margin: auto;
    }
    .news_card .main-content
{
    width: 100%;
}

}